import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const KummerkastenKlartextPage = () => (
  <Layout>
    <SEO title="KummerkastenKlartext" />

    <h1 id="ueberschrift">Kummerkasten oder auch Ballastkübel &emsp; Stand:&nbsp;{new Date().getFullYear()}</h1>

    <p style={{ fontSize: "1.2em", lineHeight: "1.7" }}>
      Kontakt:&ensp;
      <a href="tel:+49-9187-7081220" title="anrufen" style={{ fontSize: "1em"  }}>+49 (0) 91 87 / 70 81 220</a>
      &ensp;oder&ensp;
      <a href="mailto:12engel@bastet-project.de?subject=Anfrage zu einem persönlichen Gespräch" title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>12engel@bastet-project.de</a>
    </p>

    <p>
      Oft hilft es, einfach einmal "Dampf abzulassen".<br />
      Doch was tun, wenn niemand wirklich zuhört?
      </p>
    <p>
      Ich stehe Ihnen gerne zur Seite und leihe Ihnen mein Ohr.
      Wenn Sie es wünschen, dann kann ich Ihnen auch meine Hand reichen.
      Auf diese Weise können wir gemeinsam die Untiefen Ihres Lebens beleuchten.
      Sie können lernen mit den Steinen Ihres Weges eine Brücke zu bauen.
      Sie können auch lernen souverän die Hürden der Umwelt hinter sich zu lassen.
      Die Entscheidung liegt bei <u>Ihnen</u>!
      </p>
    <p>
      Selbstverständlich wird Ihr Anliegen streng vertraulich behandelt.<br />
      Ich biete Ihnen sogar die Möglichkeit für einen anonymen Termin an einem neutralen Ort an:
      d.h. Sie können sich einen Namen oder ein "Code-Wort" ausdenken und ich vertraue Ihnen,
      dass Sie zu dem vereinbarten Termin an dem vereinbarten Ort erscheinen.
      Für das erste Gespräch hat sich ein neutraler Ort, wie ein Café oder ein öffentlicher Platz, bewährt.
      </p>

    <p>
      Bitte beachten Sie, dass meine Praktiken in keiner Konkurrenz zu der professionellen
      Arbeit eines Psychiaters oder Psychologen stehen.
      Somit kann ich Sie im Falle der Notwendigkeit einer fachärztlichen
      Therapie lediglich unterstützend begleiten.
      </p>

    <h1>Preis und Zahlungsmodalitäten (Stand: 2019)</h1>
    Zahlung in Bar vor Ort oder per Vorauskasse (z.B. durch Überweisung)
    <table>
      <tr>
        <td>
          Preis pro Stunde<br />
          bei Barzahlung:
        </td>
        <td class="seccol">
          75,-- EUR
        </td>
      </tr>

      <tr>
        <td>
          Preis pro Stunde<br />
          bei Vorauskasse:
        </td>
        <td class="seccol">
          70,-- EUR
        </td>
      </tr>

      <tr>
        <td>
          sonstige Preise und<br />
          Preise der Terminkarten:
        </td>
        <td class="seccol">
          nach vorheriger Vereinbarung<br />
          bzw. nach dem Erstgespräch
        </td>
      </tr>
    </table>

  </Layout>
)

export default KummerkastenKlartextPage
